<template>
  <div id="app">
    <componentHeader />
    <router-view />
    <componentFooter />
  </div>
</template>

<script>
import componentHeader from './components/Header.vue'
import componentFooter from './components/Footer.vue'
export default {
  name: 'App',
  components:{
    componentHeader, componentFooter
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
	--preto: #151515;
	--vermelho: #C71618;
	--amarelo: #FECC16;
	--branco: #fff;
	--cinza: #B8B8B8;
}

*{
	color: var(--preto);
	font-family: 'Poppins', sans-serif;
}

.bg-amarelo{
	background-color: var(--amarelo);
}

.bg-vermelho{
	background-color: var(--vermelho);
}

.bg-branco{
	background-color: var(--branco);
}

.color-amarelo{
	color: var(--amarelo);
}

.color-vermelho{
	color: var(--vermelho);
}

.color-branco{
	color: var(--branco);
}

.font-weight-light{
	font-weight: 300;
}
.font-weight-bolder{
	font-weight: 900;
}

.font-size-big{
	line-height: 2rem;
	font-size: 3rem;
}

.border-left-cinza{
	border-left:1px solid var(--cinza);
}

.center_itens{
	display:flex;
    align-items: center;
}

.logo img{
	max-height: 70px;
}

.alert-expire{
	text-align: center;
	padding: 5px 15px;
  min-width: 270px;
}

.h-row:hover{
	background-color: #fff5f5;
    border-radius: 5px;
}

@media screen and ( max-width: 720px){
  .font-size-big{
    line-height: 2rem;
    font-size:3rem;
  }

  .logo img{
    max-height: 45px;
  }

  .alert-expire{
    text-align: center;
    padding: 3px;
    font-size: 0.6rem;
  }

  .mobile-border-left-cinza{
    border-left:1px solid var(--cinza);
  }
}
</style>
