const jwt = require('jsonwebtoken');

const secret = 'R9$oPptwDD@P@IdKF*eXQI'

module.exports = {
	verifyToken(token){
        return jwt.verify(token, secret, (err, decoded) => {            
            if(err) return { error: true, msg: 'Token inválido'}
            return decoded
		})
	},
};