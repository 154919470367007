   
export const getNumbers = (value) => {
    return value.replace(/\D/g, "");
};

export const validCVV = (cvv) => {
  var re = /^\d{3}$/;
  return re.test(cvv);
};

export const validCard = (cardNumber) => {
  let cardNumberClean = getNumbers(cardNumber);
  var re = /^\d{16}$/;
  return re.test(cardNumberClean);
};

export const validateExpDate = (expDate, minValidDate )=> {
  let expDateClean = expDate.replace(/\D/g, "");
  let minValidDateClean = minValidDate.toString().replace(/\D/g, "");
  if (expDateClean.length == 4) {
    let m = expDateClean.substring(0, 2);
    let y = expDateClean.substring(expDateClean.length - 2);
    const dateExp = new Date("20" + y + "/" + m + "/01");
    m = minValidDateClean.substring(0, 2);
    y = minValidDateClean.substring(minValidDateClean.length - 4);
    const limite = new Date( y + "/" + m + "/01");
    return dateExp >= limite;
  } else {
    return false;
  }
};

export const getFlagOptions = () => {
  let optionsbandeira = [
    {value: 1, label: 'VISA', selectedText: 'VISA', isSelected: false },
    {value: 2, label: 'MASTERCARD', selectedText: 'MASTERCARD', isSelected: false },
    {value: 33, label: 'DINERS', selectedText: 'DINERS', isSelected: false },
    {value: 3, label: 'AMERICAN EXPRESS', selectedText: 'AMERICAN EXPRESS', isSelected: false },
    {value: 5, label: 'HIPERCARD', selectedText: 'HIPERCARD', isSelected: false },
    {value: 41, label: 'ELO', selectedText: 'ELO', isSelected: false },
    {value: 0, label: 'Outros', selectedText: 'Outros', isSelected: false },       
  ]
  return optionsbandeira;

  
}
