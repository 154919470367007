<template>
  <section class="containter-fluid" id="container_main">
    <section class="container p-4">
      <h1 class="font-weight-bolder">
        Atualização de Cartão para Pagamento Recorrente
      </h1>
      <h4>A validade mínima para o cartão deve ser: {{ minValidExib }}</h4>
      <p>
        <strong
          >Informamos que para validar o cartão será realizada uma transação de
          teste no valor de R$1,00, que será estornada em seguida.</strong
        >
      </p>
      <p>
        * Caso esta transação de teste não possa ser realizada, o cartão não
        será aceito. Em alguns casos é necessário realizar contato com a Central
        de Atendimento do seu cartão para liberação do mesmo.
      </p>
      <main style="margin-top: 2rem" v-if="!sucess">
        <label class="row h-row py-2">
          <div class="col-md-3 col-sm-12 mobile-border-left-cinza py-2">
            Curso:
          </div>
          <div class="col-md-6 col-sm-12 border-left-cinza p-2">
            <span class="fw-bold text-uppercase">{{ curso }}</span>
          </div>
        </label>

        <label class="row h-row py-2">
          <div class="col-md-3 col-sm-12 mobile-border-left-cinza py-2">
            Número do Cartão de Crédito:<i class="ob color-vermelho">*</i>
          </div>
          <div class="col-8 col-md-4 border-left-cinza p-2">
            <input
              type="text"
              class="form-control"
              v-model="cardNumber"
              required
              name="cardNumber"
              v-mask="'#### #### #### ####'"
            />
          </div>
          <!--<div class="col-4 col-md-4 p-2" v-if="flag == 'X'">
            Esta bandeira de cartão não é aceita para cadastro de recorrência
             colocar imagem aqui
          </div> -->
        </label>

        <div class="row h-row py-2">
          <div class="col-md-3 col-sm-12 mobile-border-left-cinza py-2">
            Bandeira do Cartão:<i class="ob color-vermelho">*</i>
          </div>
          <div class="col-md-4 col-sm-12 border-left-cinza p-2">
            <div class="row">
              <div class="col-8">
                <div class="row">
                  <div class="col-sm-12">
                    <vs-select class="bandeiracartao" v-model="selectcard">
                      <vs-select-item
                        :is-selected.sync="item.isSelected"
                        :key="index"
                        :value="item.value"
                        :text="item.isSelected ? item.selectedText : item.label"
                        v-for="(item, index) in flagOptions"
                      />
                    </vs-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row h-row py-2">
          <div class="col-md-3 col-sm-12 mobile-border-left-cinza py-2">
            Validade do Cartão:<i class="ob color-vermelho">*</i>
          </div>
          <div class="col-md-4 col-sm-12 border-left-cinza p-2">
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-sm-12">
                    <small
                      >Mes/Ano (XXXX):<i class="ob color-vermelho">*</i></small
                    >
                  </div>
                  <div class="col-sm-12">
                    <input
                      type="text"
                      class="form-control"
                      required
                      v-model="expDate"
                      name="expDate"
                      v-mask="'##/##'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label class="row h-row py-2">
          <div class="col-md-3 col-sm-12 mobile-border-left-cinza py-2">
            Segurança(CVV):<i class="ob color-vermelho">*</i>
          </div>
          <div class="col-md-2 col-4 border-left-cinza p-2">
            <input
              type="text"
              class="form-control"
              required
              v-model="cvv"
              v-mask="'###'"
            />
          </div>
        </label>

        <div class="row py-2">
          <div class="offset-md-3 col-md-9 col-12">
            <button
              type="button"
              class="btn btn-danger px-5"
              @click="updateRM()"
            >
              Atualizar Cartão
            </button>
          </div>
        </div>
        <div class="row py-2"></div>
      </main>

      <div v-if="sucess">
        <vs-alert color="success" :active.sync="sucess">
          Cartão atualizado com sucesso!
        </vs-alert>
      </div>
      <div v-if="fail">
        <vs-alert
          color="danger"
          title="Atenção ao Erro:"
          v-for="(m, i) in message"
          :key="i"
        >
          {{ m }}
        </vs-alert>
      </div>
    </section>
  </section>
</template>

<script>
import jwt from "../middlewares/token.middleware";
import Card from "../services/card";
import {
  validCVV,
  validateExpDate,
  validCard,
  getFlagOptions,
  getNumbers,
} from "../validations/CardValidation";

export default {
  name: "page-update",
  data: () => ({
    cardNumber: "",
    expDate: "",
    cvv: "",
    flag: "-1",
    curso: null,
    minValidDate: null,
    minValidExib: null,
    tokenDecoded: null,
    token: null,
    sucess: false,
    message: [],
    fail: false,
    selectcard: null,
    flagOptions: [],
  }),
  mounted() {
    this.tokenDecoded = jwt.verifyToken(this.$route.params.token);
    this.token = this.$route.params.token;
    if (this.tokenDecoded) {
      new Card().getDataCard(this.$route.params.token).then(({ data }) => {
        this.curso = data.NOMECOMERCIAL;
        this.minValidDate = data.DATAMINVALIDADE;
        this.minValidExib = new Date(data.DATAMINVALIDADE);
        this.minValidExib = this.mountDate(this.minValidDate);
      });
    }
    this.flagOptions = getFlagOptions();
  },
  methods: {
    mountDate(data) {
      let part = data.substring(0, 7);
      let date = part.split("-");
      return date[1] + "/" + date[0];
    },
    async updateRM() {
      this.message = [];
      this.sucess = false;
      if (!validCVV(this.cvv)) {
        this.fail = true;
        this.message.push("CVV inválido");
      }
      if (!validCard(this.cardNumber)) {
        this.fail = true;
        this.message.push("Número do cartão inválido");
      }
      if (!validateExpDate(this.expDate, this.minValidExib)) {
        this.fail = true;
        this.message.push("Data de validade do cartão não aceita");
      }
      if (!this.selectcard) {
        this.fail = true;
        this.message.push("Selecione a bandeira do cartão");
      }

      if (this.message.length == 0) {
        this.flag = this.selectcard;

        const data = {
          card: {
            number: getNumbers(this.cardNumber),
            exp: getNumbers(this.expDate),
            security: this.cvv,
            flag: this.flag.toString(),
          },
          makePreOrder: true,
        };

        this.$vs.loading();
        await new Card()
          .update(data, this.token)
          .then(({ data }) => {
            console.log(data);
            this.sucess = true;
            this.$vs.loading.close();
          })
          .catch((response) => {
            console.log(response.error);
            this.sucess = false;
            this.fail = true;
            this.message.push(
              "Erro ao validar o cartão. " +
                "O cartão informado não pode ser aceito pois: " +
                "Pode não estar habilitado para compra online; " +
                "ou o número do cartão informado é inválido; " +
                "ou o cartão pode estar sem limite para realizar a transação de teste no valor de R$1,00; " +
                "ou a bandeira do seu cartão não é aceita pela QI."
            );
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>

<style scoped>
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
</style>
