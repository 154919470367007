import axios from 'axios'

export const https = (baseURL = '', token) => {
  const instance = axios.create({
    baseURL:`https://sphqi.com.br/sitef${baseURL}`,
    /*baseURL: `http://localhost:9093/sitef${baseURL}`,*/
    headers: {
      'x-access-token': token,
    },
  })
  return instance
}
