<template>
	<section class="containter-fluid" id="container_main">
		<section class="container p-4">
			<h1 class="font-weight-bolder">Token Expirado</h1>
			<p>Este link expirou e não é mais válido. Entre em contato com a nossa central de atendimento para providenciar um novo link para cadastro/alteração de cartão.</p>
			<p>Fone: 0800 000 0301</p>
		</section>
	</section>
</template>

<script>
export default {
    name: 'page-token-exp',
}
</script>

<style scoped>

</style>
