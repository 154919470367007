import Vue from 'vue'
import Vuesax from 'vuesax'
import App from './App.vue'

import 'vuesax/dist/vuesax.css'
import Vuemask from 'v-mask'
import "material-design-icons/iconfont/material-icons.css"

import VueRouter from 'vue-router'

import Home from './routes/Home.vue'
import Token from './routes/Token.vue'
import Update from './routes/Update.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

Vue.config.productionTip = false
Vue.use(Vuemask)

Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#9B0E1C',
    }
  }
})
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history', // remove o # do link
  routes:[
    {
      path: '/card',
      component: Token
    },
    {
      path: '/card/tokenexp',
      component: Token
    },
    {
      path: '/card/store/:token',
      component: Home,
      props: true
    },
    {
      path: '/card/update/:token',
      component: Update,
      props: true
    }
  ]
})

new Vue({
  router, 
  render: h => h(App),
}).$mount('#app')
