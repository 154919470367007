import { https } from './api'

export default class {
  constructor() {
    this.instance = https('/')
  }

  setInstance(token){
    this.instance = https('/', token)
  }

  store = (data, token) => {
    this.setInstance(token);
    return this.instance({
      method: 'POST',
      url: 'store/card',
      data: data,
    })
  }
  
  update = (data, token) => {
    this.setInstance(token);
    return this.instance({
      method: 'POST',
      url: 'store/update/card',
      data: data,
    })
  }

  getDataCard = (token) => {
    this.setInstance(token);
    return this.instance({
        method: 'GET',
        url: `store/card`
      })
  }

  sendMail = (data, token) =>{
    this.setInstance(token);
    return this.instance({
      method: 'POST',
      url: 'store/mail',
      data: data,
    })
  }

}