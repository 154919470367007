<template>
	<section class="containter-fluid bg-vermelho">
		<section class="container p-4">
			<nav class="row g-2">
				<div class="col  center_itens logo">
					<img src="@/assets/qi-faculdade-e-escola-tecnica-negativa.svg" class=" img-fluid"/>
				</div>
				<div class="col-auto center_itens" v-if="countdown">
					<div>
						<div class="bg-amarelo font-weight-light color-vermelho alert-expire">ESSA PÁGINA EXPIRA EM:</div>
						<div id="time-expire" class="font-size-big color-branco font-weight-bolder p-2 text-center">{{ countdown | momentTimer }}</div>
					</div>
				</div>
			</nav>
		</section>
	</section>
</template>

<script>
import jwt from '../middlewares/token.middleware'
import moment from 'moment'

export default {
    name: 'component-header',
	data: () => ({
		timeExp: null,
		countdown: null
	}),
	mounted(){
		console.log(this.$route.params.token)
		let token = jwt.verifyToken( this.$route.params.token )
		if( token.error ) {
			this.$router.push('/card/tokenexp')
		}
		this.timeExp = token.exp
		setInterval(() => {
			let timeNow = moment().unix()
			this.countdown = this.timeExp - timeNow
		}, 1000);
	},
	filters: {
		momentTimer: function (time) {
			moment.locale('pt-br');
			return moment.unix(time).format('HH:mm:ss');
		}
	}
}
</script>

<style scoped>

</style>